import { getNews } from "services/newsService";

const { createSlice } = require("@reduxjs/toolkit");

const newsSlice = createSlice({
    name: 'news',
    initialState: {
        data: [],
        currentSkip: 0,
        hasMore: false,
        filters: {
            newsId: '',
            title: '',
            authorId: '',
            shortText: '',
            fullText: '',
            categoryId: '',
            dateFrom: '',
            dateTo: ''
        }
    },
    reducers: {
        setNewsData: (state, action) => {
            return { ...state, data: action.payload }
        },
        addNewsData: (state, action) => {
            return { ...state, data: [...state.data, ...action.payload] }
        },
        setCurrentSkip: (state, action) => {
            return { ...state, currentSkip: action.payload }
        },
        setHasMore: (state, action) => {
            return { ...state, hasMore: action.payload }
        },
        setNewsFilter: (state, action) => {
            return { ...state, filters: action.payload }
        }
    }
})

export async function fetchNews(dispatch, params, skip) {
    const LIMIT = 200;
    try {
        console.log('bbb')
        const news = await getNews(params, skip);
        dispatch(setCurrentSkip(skip));

        if (skip > 0) {
            dispatch(addNewsData(news))
        }
        else {
            dispatch(setNewsData(news));
        }

        if (news.length < LIMIT) {
            dispatch(setHasMore(false));
        }
        else {
            dispatch(setHasMore(true));
        }
    }
    catch (error) {
        console.error('Erro ao obter Notícias', error)
    }
}

export async function fetchNewsFilters(dispatch, params) {
    dispatch(setNewsFilter(params));
}

export const { setNewsData, addNewsData, setCurrentSkip, setHasMore, setNewsFilter } = newsSlice.actions
export default newsSlice.reducer