import { configureStore } from '@reduxjs/toolkit'
import {
    userReducer,
    selectedUserReducer,
    cardReducer,
    loggedUserReducer,
    staffReducer,
    infractionReducer,
    categoryReducer,
    transactionReducer,
    avatarCountReducer,
    abuseReportCountReducer,
    statsReducer,
    itemReducer,
    spaceReducer,
    friendReducer,
    ipReducer,
    missionReducer,
    emailReducer,
    selectedUserDetailsReducer,
    userGroupReducer,
    chatLogReducer,
    modLogsReducer,
    selectedMissionReducer,
    selectedMissionDetailsReducer,
    userLoginReducer,
    avatarReducer,
    selectedAvatarReducer,
    selectedAvatarDetailsReducer,
    xpLogReducer,
    petReducer,
    sessionReducer,
    selectedSpaceReducer,
    selectedSpaceDetailsReducer,
    spaceMemberReducer,
    socketReducer,
    spaceChatReducer,
    selectedItemReducer,
    selectedItemDetailsReducer,
    abuseReportReducer,
    allInfractionReducer,
    selectedCategoryReducer,
    categoryDetailReducer,
    modelListReducer,
    spaceCategoryReducer,
    selectedSpaceCategoryReducer,
    spaceCategoryDetailReducer,
    interstitialGroupReducer,
    interstitialReducer,
    selectedInterstitialItemOrGroupReducer,
    selectedInterstitialGroupReducer,
    selectedInterstitialItemDetailReducer,
    selectedInterstitialGroupDetailReducer,
    spinCategoryReducer,
    selectedSpinCartegoryReducer,
    selectedSpinCategoryReducer,
    selectedSpinCategoryDetailReducer,
    selectedSpinPrizeReducer,
    selectedUserGroupReducer,
    permissionCategoryReducer,
    newsReducer,
    selectedNewsReducer,
    selectedNewsDetailReducer,
    newsCatListReducer,
    newsCategoriesReducer,
    selectedNewsCategoryReducer,
    selectedNewsCategoryDetailReducer
} from './modules';

const store = configureStore({
    reducer: {
        users: userReducer,
        cards: cardReducer,
        selectedUser: selectedUserReducer,
        loggedUser: loggedUserReducer,
        staff: staffReducer,
        infractions: infractionReducer,
        categories: categoryReducer,
        transactions: transactionReducer,
        avatarCount: avatarCountReducer,
        abuseReportCount: abuseReportCountReducer,
        stats: statsReducer,
        items: itemReducer,
        spaces: spaceReducer,
        friends: friendReducer,
        ips: ipReducer,
        missions: missionReducer,
        emails: emailReducer, 
        selectedUserDetails: selectedUserDetailsReducer,
        userGroups: userGroupReducer,
        chatLogs: chatLogReducer,
        modLogs: modLogsReducer,
        selectedMission: selectedMissionReducer,
        selectedMissionDetails: selectedMissionDetailsReducer,
        userLogins: userLoginReducer,
        avatars: avatarReducer,
        selectedAvatar: selectedAvatarReducer,
        selectedAvatarDetails: selectedAvatarDetailsReducer,
        xpLogs: xpLogReducer,
        pets: petReducer,
        sessions: sessionReducer,
        selectedSpace: selectedSpaceReducer,
        selectedSpaceDetails: selectedSpaceDetailsReducer,
        spaceMembers: spaceMemberReducer,
        socket: socketReducer,
        spaceChat: spaceChatReducer,
        selectedItem: selectedItemReducer,
        selectedItemDetails: selectedItemDetailsReducer,
        abuseReports: abuseReportReducer,
        allInfractions: allInfractionReducer,
        selectedCategory: selectedCategoryReducer,
        categoryDetails: categoryDetailReducer,
        modelList: modelListReducer,
        spaceCategories: spaceCategoryReducer,
        selectedSpaceCategory: selectedSpaceCategoryReducer,
        spaceCategoryDetails: spaceCategoryDetailReducer,
        interstitialGroups: interstitialGroupReducer,
        interstitials: interstitialReducer,
        selectedInterstitialItemOrGroup: selectedInterstitialItemOrGroupReducer,
        selectedInterstitialGroup: selectedInterstitialGroupReducer,
        selectedInterstitialItemDetails: selectedInterstitialItemDetailReducer,
        selectedInterstitialGroupDetails: selectedInterstitialGroupDetailReducer,
        spinCategories: spinCategoryReducer,
        selectedSpinCategory: selectedSpinCategoryReducer,
        selectedSpinCategoryDetails: selectedSpinCategoryDetailReducer,
        selectedSpinPrizes: selectedSpinPrizeReducer,
        selectedUserGroup: selectedUserGroupReducer,
        permissionCategories: permissionCategoryReducer,
        news: newsReducer,
        selectedNews: selectedNewsReducer,
        selectedNewsDetails: selectedNewsDetailReducer,
        newsCatList: newsCatListReducer,
        newsCategories: newsCategoriesReducer,
        selectedNewsCategory: selectedNewsCategoryReducer,
        selectedNewsCategoryDetails: selectedNewsCategoryDetailReducer,
    },
})

export default store
